<template>
  <v-container fluid>
    <v-flex>
      <v-card>
        <v-toolbar color="#0f2e8c" flat>
          <h3 class="white--text">SEARCH LOGS</h3>
          <v-spacer/>
          <v-btn
              class="mx-2"
              color="green"
              dark
              v-if="loginInfo._perm === 'admin'"
              :disabled="exportState || totalItems <= 0"
              :loading="exportState"
              @click="qrExport"
          >
            Xuất File Excel
          </v-btn>
          <v-btn
              class="mx-2"
              color="red"
              dark
              v-if="loginInfo._perm === 'admin'"
              :disabled="removeState || selectedItems.length <= 0"
              :loading="removeState"
              @click="doConfirmRemove"
          >
            Xóa
          </v-btn>
        </v-toolbar>
        <v-card-title />
        <v-card-text>
          <v-data-table
              v-model="selectedItems"
              fixed-header
              show-select
              selectable-key="_id"
              item-key="_id"
              :loading="loading"
              :headers="headers"
              :options.sync="Options"
              :server-items-length="totalItems"
              :items="items"
              loading-text="Đang tải dữ liệu..."
              :footer-props="{itemsPerPageText: 'Dữ liệu mỗi trang','items-per-page-options':[10,20,30,40,50]}"
              :items-per-page="20"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import SearchLogsHeader from '@/plugins/headers/search-logs.json';
import Query from '@/plugins/query/search-logs';
import {mapGetters} from "vuex";
import {Status} from "@/plugins/helper/dstatus";
export default {
  name: "SearchLogs",
  components:{

  },
  data(){
    return {
      removeState: false,
      exportState: false,
      selectedItems: [],
      items:[],
      loading: false,
      Options:{},
      totalItems:0,
      notice: new Status(this.$swal),
    }
  },
  computed:{
    ...mapGetters(['authStatus', 'isAuthenticated', 'loginInfo']),
    headers(){
      return SearchLogsHeader;
    }
  },
  watch: {
    Options: {
      handler () {
        return this.doDownload();
      },
      deep: true,
    },
  },
  methods:{
    ...Query,
    doDownload(){
      this.loading = true;
      return this.qrList(this.Options).then(json=>{
        if(json.data){
          const {data, total} = json;
          if(data) this.items = data;
          if(total) this.totalItems = total;
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    doConfirmRemove(){
      return this.notice.confirm("Xác nhận ? ", "Hành động này không thể phục hồi !!!").then(confirm=>{
        if(confirm) return this.doRemove();
      })
    },
    doRemove(){
      const keywords = this.selectedItems.map(item=>item._id);
      this.removeState = true;
      return this.qrDelete(keywords).then(json=>{
        const {success, error} = json;
        if(success) return this.doDownload();
        if(error) return this.notice.error(error);
      }).finally(()=>{
        this.removeState = false;
        this.selectedItems = [];
      });
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>