import { Request } from '@/plugins/helper/fetch'
import {serialize} from "@/plugins/helper/object";
const req = new Request('search-logs');
const qrList = (filter)=>req.fetch(`?${serialize(filter)}`).then(data=>data.json());
const qrExport = function(){
  this.exportState = true;
  return req.post(`export`,{export: true}).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          return this.notice.error(error);
        }
        return;
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Search-Logs.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return;
      });
    }
  }).finally(()=>this.exportState = false);
}
const qrDelete = (keywords) => req.patch(`remove`,{keywords}).then(data=>data.json());
export default {
  qrList,
  qrExport,
  qrDelete,
}